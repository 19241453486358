import firebase from "firebase/app";
import { pick } from "@/utils";
import router from "@/router";

/**
|--------------------------------------------------
| Module state
|--------------------------------------------------
*/
const state = {
  isLoggedIn: false,
  token: null,
  userDetails: null,
  showLoader: false,
  multiFactors: null,
};

/**
|--------------------------------------------------
| Mutation constants
|--------------------------------------------------
*/
const SET_CURRENT_USER = "SET_CURRENT_USER";
const LOG_OUT_CURRENT_USER = "LOG_OUT_CURRENT_USER";
const SET_TOKEN = "SET_TOKEN";
const SHOW_LOADER = "SHOW_LOADER";
const SET_MULTI_FATCOR_DETAILS = "SET_MULTI_FATCOR_DETAILS";

/**
|--------------------------------------------------
| Mutations
|--------------------------------------------------
*/
const mutations = {
  [SET_CURRENT_USER](state, data) {
    state.userDetails = data;
    state.isLoggedIn = true;
  },
  [SET_TOKEN](state, data) {
    state.token = data;
  },
  [LOG_OUT_CURRENT_USER](state) {
    state.userDetails = null;
    state.isLoggedIn = false;
    state.token = null;
    state.multiFactors = null;
  },
  [SHOW_LOADER](state, data) {
    state.showLoader = data;
  },
  [SET_MULTI_FATCOR_DETAILS](state, data) {
    state.multiFactors = data;
  },
};

/**
|--------------------------------------------------
| Actions
|--------------------------------------------------
*/
const actions = {
  async setCurrentUserDetails({ commit, dispatch }, userDetails) {
    try {
      const jwt = await firebase.auth().currentUser.getIdToken();
      const userProps = ["email", "emailVerified", "refreshToken", "uid"];

      commit(SET_CURRENT_USER, pick(userDetails.user, userProps));
      commit(SET_TOKEN, jwt);

      router.push("/dashboard");
      await dispatch("getCurrentUserMultiFactor");
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async getCurrentUserMultiFactor({ commit }) {
    try {
      const multiFactors = firebase.auth().currentUser.multiFactor;

      commit(SET_MULTI_FATCOR_DETAILS, multiFactors.enrolledFactors);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async logoutUser({ commit }) {
    try {
      await firebase.auth().signOut();
      commit(LOG_OUT_CURRENT_USER);
    } catch (error) {
      return Promise.reject(error);
    }
  },
};

/**
|--------------------------------------------------
| Getters
|--------------------------------------------------
*/
const getters = {
  isLoggedIn({ isLoggedIn }) {
    return isLoggedIn;
  },
  token({ token }) {
    return token;
  },
  userDetails({ userDetails }) {
    return userDetails;
  },
  showLoader({ showLoader }) {
    return showLoader;
  },
  multiFactors({ multiFactors }) {
    return multiFactors;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
