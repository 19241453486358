<template>
  <div id="overlay" v-if="isLoaderVisible">
    <div class="loader-wrapper">
      <div class="spinner-border loader" role="status" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

/**
 * Loader`
 */
export default {
  name: "Loader",
  /**
  |--------------------------------------------------
  | Props
  |--------------------------------------------------
  */
  props: {
    useVuex: { type: Boolean, default: true },
    isLoading: { type: Boolean, default: false },
  },
  /**
  |--------------------------------------------------
  | Computed Properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      showLoader: "auth/showLoader",
    }),
    /**
     * Show loader to the user
     * @type {Boolean}
     */
    isLoaderVisible() {
      return !this.useVuex ? this.isLoading : this.showLoader;
    },
  },
};
</script>

<style lang="css">
.loader {
  width: 5rem !important;
  height: 5rem !important;
  color: #04aa6d !important;
}
.loader-wrapper {
  height: 100vh;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
#overlay {
  position: fixed; /* Sit on top of the page content */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  display: block !important;
}
</style>
