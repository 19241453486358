<template>
  <div id="nav">
    <div id="auth-route__links" v-if="!isLoggedIn">
      <h1>MultiFactor Auth Poc</h1>
      <router-link to="/signup">Sign Up</router-link>
      <br />
      <router-link to="/signin">Login MultiFactor</router-link>
    </div>
    <div v-else>
      <div class="d-flex">
        <div class="imgcontainer mx-5">
          <img
            width="300"
            src="https://www.maropost.com/wp-content/themes/Maropost/img/logos/Maropost_Logo.svg"
            alt="Avatar"
          />
        </div>
        <div class="d-flex justify-content-end w-100 align-items-center mx-5">
          <div>
            <button @click="logout" type="button" class="btn btn-link">
              {{ authActionText }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

/**
 * App Header
 */
export default {
  name: "AppHeader",
  /**
  |--------------------------------------------------
  | Computed properties
  |--------------------------------------------------
  */
  computed: {
    ...mapGetters({
      isLoggedIn: "auth/isLoggedIn",
    }),
    /**
     * Auth button text on the basis of use auth state
     */
    authActionText() {
      return this.isLoggedIn ? "Log out" : "";
    },
  },
  /**
  |--------------------------------------------------
  | Method
  |--------------------------------------------------
  */
  methods: {
    ...mapActions({
      logoutUser: "auth/logoutUser",
    }),
    /**
     * Logs out user from the application and redirects to dashboard
     */
    async logout() {
      await this.logoutUser();
      this.$router.push("/signin");
    },
  },
};
</script>
