import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
});

/**
 * Router middleware
 */
router.beforeEach((to, from, next) => {
  const isLoggedIn = store.getters["auth/isLoggedIn"];
  const isPrivate = to.matched.some(({ meta }) => meta.isPrivate);

  if (isLoggedIn && !isPrivate) next("/dashboard");
  else if (to.name !== "signin" && !isLoggedIn && isPrivate) {
    next("/signin");
  } else next();
});

export default router;
