import Vue from "vue";
import App from "./App.vue";
import "@/firebase.js";
import router from "./router";
import "@/assets/app.css";
import "bootstrap/dist/css/bootstrap.min.css";
import store from './store'

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");
