const routes = [
  {
    path: "/signin",
    name: "signin",
    component: () => import("@/views/SignIn.vue"),
  },
  {
    path: "/signup",
    name: "sign-up",

    component: () => import("@/views/SignUp.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    meta: {
      isPrivate: true,
    },
    component: () => import("@/views/Dashboard.vue"),
  },
];

export default routes;
