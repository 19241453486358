import firebase from "firebase/app";
import "firebase/auth";

/**
 * Env variables constant
 */
const ENV_VARIABLES = process.env

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: ENV_VARIABLES.VUE_APP_FIREBASE_API_KEY,
  authDomain:ENV_VARIABLES.VUE_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: ENV_VARIABLES.VUE_APP_FIREBASE_DATABASE_URL,
  projectId: ENV_VARIABLES.VUE_APP_FIREBASE_PROJECT_ID,
  storageBucket: ENV_VARIABLES.VUE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId:  ENV_VARIABLES.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: ENV_VARIABLES.VUE_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
