import Vue from "vue";
import Vuex from "vuex";
import auth from "./modules/auth";

import { isDev } from "@/utils";
import VuexPersist from "@/plugins/vuex-persist.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: { auth },
  devtools: isDev(),
  plugins: [VuexPersist.plugin],
});
