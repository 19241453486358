export const isDev = () => process.env.NODE_ENV === "development";

/**
 * Creates a new object containing all key value pair properties present in the props array
 * @param {Object} object Object from which the properties are to be fetched
 * @param {Array} props Array of string (Object properties) name which are to be fetched from object argument and then added into new object
 * @returns {Object} New object containing properties
 */
export const pick = (object, props) => {
  let newObject = {};

  // Iterates over props array and add key value pair in newObject
  props.forEach((prop) => {
    // Checks whether property exixts in exixting object
    const hasProp = Object.hasOwnProperty.call(object, prop);
    if (hasProp) newObject[prop] = object[prop];
  });

  return newObject;
};

/**
 * Checks if the object is empty or not
 * @param {Object,Array} object Object to check if it is empty or not
 * @returns {Boolean} Boolean value determining if object is empty or not
 */
export const isEmpty = (object) => {
  if (Array.isArray(object)) {
    return object && object.length === 0;
  } else if (object && isType(object, "object") && object instanceof Object) {
    return Object.keys(object).length === 0 && object.constructor === Object;
  } else {
    return true;
  }
};

/**
 * Checks if the object is of a particular data type
 * @param {*} object Object to check it's data type
 * @param {*} type Data type of the object to be expected
 * @returns Boolean Whether it of specified data type
 */
export const isType = (object, type) => typeof object === type;
